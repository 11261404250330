<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box chat-dialog">
      <div class="layout-chatmain--chat-history-box">
         <template v-for="(item, index) in talkHistory">
          <div v-if="item.SNDRCV_CD == 'RCV'" style="background: transparent !important; text-align: left; padding: 0 0 0 6px;">{{ item.CUSTOMER_NM }}</div>
          <div v-if="item.SNDRCV_CD == 'SND'" style="background: transparent !important; text-align: right; padding: 0 0 0 6px;">{{ item.USER_ID }}</div>
          <div :class="
            item.SNDRCV_CD == 'RCV'
              ? 'layout-chatmain--chat-history--cs-msg'
              : 'layout-chatmain--chat-history--manager-msg'
          " :key="index">
            <img v-if="item.TYPE=='IM' || 'photo'" :src="item.IMAGE_URL">         
            <div v-if="item.TYPE!='photo'">
              {{ item.CONTENT }}
            </div>
            <div v-if="item.TYPE!='photo'" class="layout-chatmain--chat-history--time" :key="index">
              {{ item.CHAT_DATE }}
            </div>
          </div>
        </template>
      </div>
      <template v-if="HAS_BTN" >
        <div class="layout-chatmain--chat-history--form mt-2">
          <v-textarea
           label=""
           no-resize
           outlined
           placeholder="내용을 입력하세요"
           background-color="white"
           dense
           class="layout-chatmain--chat-history--form-textarea textarea-type0"
           v-model.trim="CHAT_MSG"
           v-on:keyup.enter="sendMsg"
           ></v-textarea>
        </div>
        <div class="text-right mt-2">
          <v-btn
            outlined
            class="btn-point ml-auto"
            v-on:click="sendMsg"
            >
              전송
            </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import api from "@/store/apiUtil.js";
import apiChat from "@/store/apiChatParamUtil.js";
import { mixin } from "@/mixin/mixin.js"

export default {
  name: "MENU_ChatHistoryModal", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    params:{
      type: Object,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
    HAS_BTN: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CHAT_MSG: '',
      talkHistory: [],
      CLK_CUST:'',
      CLK_CHAT:'',
      CLK_USER:'',
      CLK_SENDER:'',
      CLK_MSGSKIP:'',
      CLK_STAT:'',

      CHT_RESULT:[],
      CHT_NEWRESULT:'',

    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  beforeUpdate() {
    //console.log("Beforeupdate!");
    //this.getTalkInfo();
  },  
  watch:{
    headerTitle: {
      handler(val, oldVal) {
        this.getTalkInfo();
      },
    }
  },
  methods: {
    init(){
        console.log("Params::::");
        console.log(this.params);
        this.getTalkInfo();
    },
    //상담 상세 정보
    getTalkInfo(){
      apiChat.post("api/chat/main/cnslt-cn/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.params.ASP_NEWCUST_KEY,
        TALK_CONTACT_ID: this.params.TALK_CONTACT_ID,
        TALK_USER_KEY: this.params.TALK_USER_KEY,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.params.ASP_CUST_KEY,
        USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        action:'api/chat/main/cnslt-cn/inqire',
        method:'post'
      },
      {head: {
      }})
      .then((response) => {
        console.log("채팅정보>>>>>>>>",response.data.data);
        this.talkHistory = response.data.data;
      })
      .catch((err) => {
        alert(err);
      })
    },

    sendMsg() {
      console.log('sendMsg');
    }
  },
  filters: {

  },
  computed: {

  },
}
</script>

<style></style>
